* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  background: black linear-gradient(to bottom, #062430, black) no-repeat;
  height: 100vh;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1100px;
  margin: auto;
  padding: 0 20px;
}

.btn {
  display: inline-block;
  color: #fff;
  background-color: #3fb573;
  font-size: 1em;
  text-align: center;
  padding: 10px 15px;
  border: 0;
  margin: 10px 0;
}

header {
  height: 200px;
  margin-bottom: 40px;
}

header img {
  width: 600px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search {
  height: 100px;
}

input[type='text'] {
  display: block;
  padding: 10px;
  font-size: 20px;
  border: 0;
  border-radius: 5px;
  width: 60%;
  margin: auto;
  outline: none;
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.card {
  cursor: pointer;
  background-color: transparent;
  height: 300px;
  perspective: 1000px;
  text-transform: uppercase;
  line-height: 25px;
}

.card h1 {
  font-size: 25px;
  border-bottom: 1px #fff solid;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.9s;
  transform-style: preserve-3d;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-back {
  background-color: #062430;
  color: rgb(255, 255, 255);
  padding: 20px;
  transform: rotateY(180deg);
}

.card li {
  list-style: none;
  padding-bottom: 10px;
}

@media (max-width: 800px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
  header img {
    width: 400px;
  }
}

@media (max-width: 500px) {
  .cards {
    grid-template-columns: 1fr;
  }
  .card img {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
  header img {
    width: 200px;
  }
}
